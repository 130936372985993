import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/.pnpm/@sanity+next-loader@1.1.0_@sanity+client@6.22.2_next@14.2.16_react@18.3.1/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/.pnpm/@sanity+next-loader@1.1.0_@sanity+client@6.22.2_next@14.2.16_react@18.3.1/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/.pnpm/next-sanity@9.8.7_@sanity+client@6.22.2_@sanity+icons@3.4.0_@sanity+types@3.62.2_@sanity+ui@2_ua2u4tr6bjo4m66evbw2ikb624/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/Singleton/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoProvider"] */ "/opt/build/repo/src/components/VideoContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/opt/build/repo/src/components/VideoPlayer.tsx");
